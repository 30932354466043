<template>
  <v-container>
    <h1>
    FUTF:s frågelåda!
    </h1>
    <p>Här kan du skicka frågor till FUTF och se svar på de frågor som anses vara relevanta</p>
    <v-card
    class="ma-4">
      <h3 class="ml-2">Skriv en fråga till FUTF!</h3>
      <v-textarea
      v-model="question"
      counter="100"
      auto-grow
      rows="1"
      class="ma-4"
      ></v-textarea>
      <v-card-actions>
        <v-btn color="primary" @click='createQuestion'>Submit</v-btn>
      </v-card-actions>
      </v-card>
      <body
      class="mt-10 ma-4">
        <h2>Här finns svar på populära frågor</h2>
        <v-card v-for="item in questionObject" :key="item.id">
          <p class='pl-4 pt-4'> Fråga: {{ item.question_txt }} </p>
          <p class="pl-4 font-weight-bold"> Svar: {{ item.answer }} </p>
          <p class='pl-4 font-italic primary--text'> Publicerad: {{ formatDate(item.publishedtime) }}</p>
        </v-card>
        <v-dialog
          v-model="dialog"
          activator="parent"
          width="50%"
        >
          <v-card>
            <v-card-title class="text-h3 justify-center">
              Din fråga har registrerats
            </v-card-title>
            <TuppSnurr/>
            <v-card-actions>
              <v-btn color="primary" block @click="pushback">Tillbaka till startsidan</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </body>
    </v-container>
</template>

<script>
import axios from 'axios'
import TuppSnurr from './TuppSnurr.vue'

export default {
  components: {
    TuppSnurr
  },
  data: () => ({
    question: undefined,
    questionObject: undefined,
    dialog: false
  }),
  mounted: function () {
    axios.get('/api/questions/allanswered')
      .then((result) => {
        this.questionObject = result.data
        this.sort()
      })
  },
  methods: {
    pushback () {
      this.dialog = false
      this.$router.push('/')
    },
    createQuestion: function () {
      axios.post('api/questions/create', {
        question_txt: this.question
      })
        .then((res) => {
          this.dialog = true
        })
    },
    sort () {
      this.questionObject.sort((a, b) => {
        return new Date(b.publishedtime) - new Date(a.publishedtime)
      })
    },
    formatDate (dateString) {
      const date = new Date(dateString)
      return date.toLocaleDateString('sv-SE', { year: 'numeric', month: '2-digit', day: '2-digit' })
    }
  }
}
</script>
